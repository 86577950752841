<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import useVuelidate from "@vuelidate/core";

import {
    required,
    helpers,
    email,
} from "@vuelidate/validators";
export default {
    components: { Layout, PageHeader },
    data() {
        return {
            title: "Ajout d'un agent de traitement",
            items: [
                {
                    text: "Agents de traitement",
                    href: "/settings/treatment-officers",
                },
                {
                    text: "Ajouter un agent de traitement",
                    active: true,
                },
            ],
            form: {
                fname: "",
                lname: "",
                city: "",
                adresse: "",
                email: "",
                phone: "",
            },

            submitted: false,
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations: {
        form: {
            fname: {
                required: helpers.withMessage("First Name is required", required),
            },
            lname: {
                required: helpers.withMessage("Last Name is required", required),
            },
            city: { required: helpers.withMessage("City is required", required) },
            adresse: { required: helpers.withMessage("Adresse is required", required) },
            email: { required: helpers.withMessage("Email is required", required), email },
            phone: { required: helpers.withMessage("Phone number is required", required), },
        },
    },
    methods: {
        formSubmit() {
            this.submitted = true;
            // stop here if form is invalid
            this.v$.$touch();
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Formulaire d'ajout d'un agent de traitement</h4>

                        <form class="needs-validation" @submit.prevent="formSubmit">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom01">Nom de famille</label>
                                        <input id="validationCustom01" v-model="form.fname" type="text"
                                            class="form-control" placeholder="First name" :class="{
                                                'is-invalid': submitted && v$.form.fname.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.fname.$error" class="invalid-feedback">
                                            <span v-if="v$.form.fname.required.$message">{{
                                                    v$.form.fname.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom02">Prénoms</label>
                                        <input id="validationCustom02" v-model="form.lname" type="text"
                                            class="form-control" placeholder="Last name" :class="{
                                                'is-invalid': submitted && v$.form.lname.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.lname.$error" class="invalid-feedback">
                                            <span v-if="v$.form.lname.required.$message">{{
                                                    v$.form.lname.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom05">Numéro téléphone</label>
                                        <input id="validationCustom05" v-model="form.phone" type="number"
                                            class="form-control" placeholder="228 90 08 07 77" :class="{
                                                'is-invalid': submitted && v$.form.phone.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.phone.$error" class="invalid-feedback">
                                            <span v-if="v$.form.phone.required.$message">{{
                                                    v$.form.phone.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom05">Adresse email</label>
                                        <input id="validationCustom05" v-model="form.email" type="email"
                                            class="form-control" placeholder=".@gmail.com" :class="{
                                                'is-invalid': submitted && v$.form.email.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.email.$error" class="invalid-feedback">
                                            <span v-if="v$.form.email.required.$message">{{
                                                    v$.form.email.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom03">Ville</label>
                                        <input id="validationCustom03" v-model="form.city" type="text"
                                            class="form-control" placeholder="City" :class="{
                                                'is-invalid': submitted && v$.form.city.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.city.$error" class="invalid-feedback">
                                            <span v-if="v$.form.city.required.$message">{{
                                                    v$.form.city.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="mb-3">
                                        <label for="validationCustom04">Adresse (Localité)</label>
                                        <input id="validationCustom04" v-model="form.adresse" type="text"
                                            class="form-control" placeholder="adresse" :class="{
                                                'is-invalid': submitted && v$.form.adresse.$error,
                                            }" />
                                        <div v-if="submitted && v$.form.adresse.$error" class="invalid-feedback">
                                            <span v-if="v$.form.adresse.required.$message">{{
                                                    v$.form.adresse.required.$message
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="form-check mb-3">
                                <input class="form-check-input" type="checkbox" id="invalidCheck" required="" />
                                <label class="form-check-label" for="invalidCheck">
                                    Vous confirmez les informations saisies ?
                                </label>
                                <div class="invalid-feedback">
                                    You must agree before submitting.
                                </div>
                            </div>
                            <div class="mb-3 mb-0">
                                <div>
                                    <button type="reset" class="btn btn-secondary ms-1 me-3">
                                        Cancel
                                    </button>
                                    <button class="btn btn-primary" type="submit">Enregistrer l'agent</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>

    </Layout>
</template>